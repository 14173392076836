#home-background {
  position: absolute;
  z-index: -1;
  top: 0;
  background-image: url("/assets/background/christopher-gower.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100vw;
  min-height: 100vh;
}

#homepage-text {
  width: 100%;
  top: 60%;
  align-items: center;
  /* line-height: 70px; */
  font-size: 200%;
  font-family: Roboto, sans-serif !important;
  position: absolute;
  color: white;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin: 0 auto;
  opacity: 0;
  text-align: center;
  text-shadow: 0px 0px 3px black;
  animation: fadeInTitle 3s ease forwards 3s;
}

@keyframes fadeInTitle {
  from {
    opacity: 0;
    filter: blur(5px);
  }
  to {
    opacity: 1;
  }
}

#dev-logo {
  width: 100%;
  top: 40%;
  color: white;
  position: absolute;
  margin: 0 auto;
  filter: drop-shadow(0px 0px 5px rgb(0, 0, 0));
  animation: fill-white 1s ease forwards 2s;
}

#dev-logo path:nth-child(1) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards;
}
#dev-logo path:nth-child(2) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards 0.2s;
}
#dev-logo path:nth-child(3) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards 0.4s;
}
#dev-logo path:nth-child(4) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards 0.6s;
}
#dev-logo path:nth-child(5) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards 0.8s;
}
#dev-logo path:nth-child(6) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards 1s;
}
#dev-logo path:nth-child(7) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards 1.2s;
}
#dev-logo path:nth-child(8) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 2s ease forwards 1.4s;
}
#dev-logo path:nth-child(9) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: line-animation 3s ease forwards 1.6s;
}

@keyframes line-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-white {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}
