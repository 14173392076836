.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  overflow-x: hidden !important;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

#about-background {
  position: absolute;
  z-index: 0;
  top: 0;
  background-image: url("/assets/background/joshua-reddekopp.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 650px;

  /* height: 300px; */
}

#projects-background {
  position: absolute;
  z-index: 0;
  top: 0;
  background-image: url("/assets/background/safar-safarov.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 650px;
  /* height: 300px; */
}

#resume-background {
  position: absolute;
  z-index: 0;
  top: 0;
  background-image: url("/assets/background/emile-perron.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 650px;
  /* height: 300px; */
}

#contact-background {
  position: absolute;
  z-index: 0;
  top: 0;
  background-image: url("/assets/background/obi-onyeador.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 650px;

  /* height: 300px; */
}

#not-found-background {
  position: absolute;
  z-index: 0;
  top: 0;
  background-image: url("/assets/background/markus-spiske.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100vw;
  min-height: 100vh;
}

#about-picture {
  margin-top: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 350px;
}
@media (max-width: 767px) {
  #about-picture {
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 500px) {
  #about-picture {
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
  }
}

.about-image {
  max-width: 100%;
  height: auto;
  min-width: 320px;
  overflow: hidden;
  border: 2px solid rgb(197, 197, 197);
  border-radius: 50px 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 979px) {
  .about-image {
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}

#about-text {
  font-family: Roboto, sans-serif !important;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
}
@media screen and (min-width: 400) {
  #about-text {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
  }
}
@media screen and (min-width: 772px) {
  #about-text {
    margin-top: 50px;
    margin-bottom: 10px;
    padding-top: 0px;
  }
}
@media screen and (min-width: 1024px) {
  #about-text {
    margin-left: 70px;
    margin-top: 90px;
    margin-bottom: 10px;
    padding-top: 0px;
  }
}
@media screen and (min-width: 1500px) {
  #about-text {
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 50px;
  }
}

.menu-items {
  color: white;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
}
@media (max-width: 979px) {
  .menu-items {
    font-size: 14px;
    padding: 0px 3px 0px 3px;
  }
}

.menu-items:hover {
  color: rgb(255, 216, 110) !important;
}

.navbar-brand {
  font-size: 20px;
  color: white;
  font-family: "Raleway", sans-serif;
}
@media (max-width: 750px) {
  .navbar-brand {
    font-size: 16px;
    padding: 5px;
  }
}

.navbar-brand:hover {
  color: rgb(255, 216, 110) !important;
}

#info {
  padding-top: 40px;
  padding-left: 20px;
  color: white;
}
@media (max-width: 979px) {
  #info {
    padding-top: 100px;
  }
}

.lower-content {
  position: absolute;
  top: 650px;
}

#center-text {
  width: 100%;
  top: 40%;
  align-items: center;
  line-height: 70px;
  font-size: 80px;
  font-family: Roboto, sans-serif !important;
  position: absolute;
  color: white;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin: 0 auto;
  text-align: center;
  text-shadow: 0px 0px 3px black;
}
@media (max-width: 979px) {
  #center-text {
    font-size: 40px;
  }
}

.nav-pills .nav-link.active {
  color: black;
  font-weight: bold;
  font-size: 18px;
  background-color: white;
}

.nav-link {
  font-size: 18px;
  font-family: Roboto, sans-serif !important;
  color: rgba(160, 160, 160, 0.466);
  text-shadow: 0px 0px 1px rgb(109, 109, 109);
}
.nav-link:hover {
  color: black;
}

.tab-pane {
  padding: 15px;
}

.project-images-container {
  display: inline-block;
  line-height: 607.72px;
  width: 100%;
  height: 607.72px;
  text-align: center;
}
@media (max-width: 979px) {
  .project-images-container {
    height: 315px;
    line-height: 315px;
  }
}

.project-images {
  max-width: 100%;
  max-height: 607.72px;
  vertical-align: middle;
}
@media (max-width: 979px) {
  .project-images {
    max-height: 100%;
    top: 0;
  }
}

.project-row {
  border: 2px solid rgb(173, 173, 173);
  border-radius: 10px 100px;
}
@media (max-width: 979px) {
  .project-row {
    border-radius: 10px 10px;
    height: 400px;
  }
}

.project-image-outer-container {
  padding: 40px;
}

.project-text {
  top: 120px;
}
@media (max-width: 1209px) {
  .project-text {
    top: 40px;
    margin-bottom: 30px;
  }
}

#github-logo {
  height: 100%;
}

#download-icon {
  height: 40px;
}

.download-column {
  margin-top: 150px;
}
@media (max-width: 1600px) {
  .download-column {
    margin-top: 170px;
  }
}
@media (max-width: 1300px) {
  .download-column {
    margin-top: 180px;
  }
}
@media (max-width: 879px) {
  .download-column {
    margin-top: 200px;
  }
}
@media (max-width: 550px) {
  .download-column {
    margin-top: 150px;
    margin-bottom: 30px;
  }
}
@media (max-width: 550px) {
  .download-column {
    margin-top: 150px;
    margin-bottom: 30px;
  }
}
@media (max-width: 520px) {
  .download-column {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.next-button {
  height: "50px";
  width: "50px";
  position: "absolute";
  top: "45%";
  left: "0";
}

.prev-button {
  height: "50px";
  width: "50px";
  position: "absolute";
  top: "45%";
  right: "0";
}

.next-button:hover {
  background-color: rgb(224, 224, 224);
  border: 3px solid black;
  border-radius: 50%;
  z-index: 1000;
  color: blue;
}

.prev-button:hover {
  background-color: rgb(224, 224, 224);
  border: 3px solid black;
  border-radius: 50%;
  z-index: 1000;
}
